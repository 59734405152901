import React from "react";

const Isi = () => {
  return (
    <div className="important-info sarclisa pt-1">
      <h4 className="m-t-0">What is SARCLISA?</h4>
      <p>SARCLISA is a prescription medicine used in combination with:</p>

      <p className="m-b-0 hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> The medicines pomalidomide and dexamethasone, to treat adults who have received at least 2 prior
therapies including lenalidomide and a proteasome inhibitor to treat multiple myeloma.
      </p>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> The medicines carfilzomib and dexamethasone, to treat adults with multiple myeloma who have
already received 1 to 3 lines of treatment and they did not work or are no longer working.
      </p>
      <p>It is not known if SARCLISA is safe and effective in children.</p>
      <h4>Important Safety Information</h4>

      <p>
        <strong>Do not receive SARCLISA if</strong> you have a history of a severe allergic reaction to isatuximab-irfc or any of
the ingredients in SARCLISA (see the list of ingredients in the full {" "}
        <a href="https://products.sanofi.us/Sarclisa/sarclisa.pdf" id="patient-sarclisa-resource-pi" target="_blank">Prescribing Information</a>).
      </p>
      <p>
        <strong>
        Before receiving SARCLISA, tell your healthcare provider about all of your medical conditions,
including if you:
        </strong>
      </p>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> Have heart problems, if your healthcare provider prescribes SARCLISA in combination with
carfilzomib and dexamethasone for you.
      </p>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> Have had shingles (herpes zoster).
      </p>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> Are pregnant or plan to become pregnant. SARCLISA may harm your unborn baby.
      </p>
      <p className="m-l-20 hang-20 p-l-20">
        <span className="bold dash">&ndash;</span>Females who are able to become pregnant should use an effective method of birth control during
treatment and for 5 months after your last dose of SARCLISA. Talk to your healthcare provider
about birth control methods that you can use during this time.
      </p>
      <p className="m-l-20 hang-20 p-l-20">
        <span className="bold dash">&ndash;</span>Tell your healthcare provider right away if you think you are pregnant or become pregnant during
treatment with SARCLISA.
      </p>
      <p className="m-l-20 hang-20 p-l-20">
        <span className="bold dash">&ndash;</span>Before receiving SARCLISA in combination with pomalidomide, females and males must agree
to the instructions in the pomalidomide REMS program. The pomalidomide REMS program
has specific requirements about birth control, pregnancy testing, blood donation, and sperm
donation that you need to know. Talk to your healthcare provider to learn more about
pomalidomide.
      </p>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> Are breastfeeding or plan to breastfeed. It is not known if SARCLISA passes into your breast milk. You
should not breastfeed during treatment with SARCLISA.
      </p>
      <p>
        <strong>Tell your healthcare provider about all the medicines you take,</strong> including prescription and over-thecounter
medicines, vitamins, and herbal supplements.
      </p>
      <h4>
        How will I receive SARCLISA?
      </h4>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> SARCLISA will be given to you by your healthcare provider by intravenous (IV) infusion into your vein.
      </p>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> SARCLISA is given in treatment cycles of 28 days (4 weeks), together with either the medicines
pomalidomide and dexamethasone, or carfilzomib and dexamethasone.
      </p>
      <p className="m-l-20">
        <span className="dash bold">&ndash;</span> In cycle 1, SARCLISA is usually given weekly.
      </p>
      <p className="m-l-20">
        <span className="dash bold">&ndash;</span> Starting in cycle 2, SARCLISA is usually given every 2 weeks.
      </p>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> If you miss any appointments, call your healthcare provider as soon as possible to reschedule your
appointment.
      </p>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> Your healthcare provider will give you medicines before each dose of SARCLISA to help reduce the
risk of infusion reactions (make them less frequent and severe).
      </p>
      <h4>
        What are the possible side effects of SARCLISA?
      </h4>
      <p>
        <strong>SARCLISA may cause serious side effects, including:</strong>
      </p>
      <p>
        <span className="orange-bull bold">&bull;</span> <strong>Infusion reactions.</strong> Infusion reactions are common with SARCLISA and can sometimes be severe or
life threatening.
      </p>
      <p className="m-l-20 hang-20 p-l-20">
        <span className="bold dash">&ndash;</span>Your healthcare provider will prescribe medicines before each infusion of SARCLISA to help
decrease your risk for infusion reactions or to help make any infusion reaction less severe. You will
be monitored for infusion reactions during each dose of SARCLISA.
      </p>
      <p className="m-l-20 hang-20 p-l-20">
        <span className="bold dash">&ndash;</span>Your healthcare provider may slow down or stop your infusion, or completely stop treatment with
SARCLISA if you have an infusion reaction.
      </p>
      <p className="p-l-20">
        <strong>
        Get medical help right away if you develop any of the following symptoms of infusion reaction
during or after an infusion of SARCLISA:
        </strong>
      </p>

      <div className="sarclisa-symptoms-grid">
        <div>        
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>shortness of breath,
wheezing, or trouble
breathing
          </p>
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>swelling of the face,
mouth, throat, or tongue
          </p>
        </div>

        <div>      
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>throat tightness
          </p>
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>palpitations
          </p>
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>dizziness, lightheadedness,
or fainting</p>
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>headache
          </p>
        </div>
        <div>      
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>cough
          </p>
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>rash or itching
          </p>
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>nausea
          </p>
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>runny or stuffy nose
          </p>
          <p className="m-l-20 hang-20 p-l-20">
            <span className="bold dash">&ndash;</span>chills
          </p>
        </div>
      </div>

      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> <strong>Decreased white blood cell counts.</strong>  Decreased white blood cell counts are common with SARCLISA
and certain white blood cells can be severely decreased. You may have an increased risk of getting
certain infections, such as upper and lower respiratory tract infections and urinary tract infections.
      </p>
      <p className="m-l-20 hang-20 p-l-20">
      <span className="bold dash">&ndash;</span>Your
healthcare provider will check your blood cell counts during treatment with SARCLISA. Your
healthcare provider may prescribe an antibiotic or antiviral medicine to help prevent infection,
or a medicine to help increase your white blood cell counts during treatment with SARCLISA.
      </p>
      <p className="m-l-20 hang-20 p-l-20">
      <span className="bold dash">&ndash;</span><strong>Tell
your healthcare provider right away if you develop any fever or symptoms of infection
during treatment with SARCLISA.</strong></p>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> <strong>Risk of new cancers. </strong> 
        New cancers have happened in people during treatment with SARCLISA. Your
healthcare provider will monitor you for new cancers during treatment with SARCLISA.
      </p>
      <p className="hang-20 p-l-20">
        <span className="orange-bull bold">&bull;</span> <strong>Change in blood tests. </strong> 
        SARCLISA can affect the results of blood tests to match your blood type. Your
healthcare provider will do blood tests to match your blood type before you start treatment with
SARCLISA. <strong>Tell all of your healthcare providers that you are being treated with SARCLISA before
receiving blood transfusions.</strong>
      </p>
      
      <p>
        <strong>The most common side effects of SARCLISA in combination with pomalidomide and dexamethasone
include:</strong>
      </p>
      <div className="two-col-sarclisa m-b-16">
        <div>
          <p className="hang-20 p-l-20 m-b-5">
            <span className="orange-bull bold">&bull;</span> upper respiratory tract infection
          </p>
          <p className="hang-20 p-l-20 m-b-5">
            <span className="orange-bull bold">&bull;</span> lung infection (pneumonia)
          </p>
          <p className="hang-20 p-l-20 m-b-5">
            <span className="orange-bull bold">&bull;</span> decreased red blood cell count (anemia)
          </p>
        </div>
        <div>
          <p className="hang-20 p-l-20 m-b-5">
            <span className="orange-bull bold">&bull;</span> diarrhea
          </p>
          <p className="hang-20 p-l-20 m-b-5">
            <span className="orange-bull bold">&bull;</span> decreased platelet count
(thrombocytopenia)
          </p>
        </div>
      </div>
      <p>
        <strong>The most common side effects of SARCLISA in combination with carfilzomib and dexamethasone
include:</strong>
      </p>
      <div className="two-col-sarclisa">
        <div>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> upper respiratory tract infection
          </p>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> tiredness and weakness
          </p>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> high blood pressure
          </p>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> diarrhea
          </p>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> lung infection (pneumonia)
          </p>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> trouble breathing
          </p>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> trouble sleeping
          </p>
        </div>
        <div>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> bronchitis
          </p>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> cough
          </p>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> back pain
          </p>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> decreased red blood cell count (anemia)
          </p>
          <p className="m-b-5 hang-20 p-l-20">
            <span className="orange-bull bold">&bull;</span> decreased platelet count (thrombocytopenia)
          </p>
        </div>
      </div>


      <p className="">
      Heart failure can happen during treatment with SARCLISA in combination with carfilzomib and
dexamethasone. <strong>Tell your healthcare provider right away if you develop any of the following
symptoms:</strong>
      </p>
      <p className="">
        <span className="orange-bull bold">&bull;</span>trouble breathing<br className="d-none mobile-show"/>
        <span className="orange-bull bold p-l-40 mobile-p-l-0">&bull;</span>cough<br className="d-none mobile-show"/>
        <span className="orange-bull bold p-l-40 mobile-p-l-0">&bull;</span>swelling of your ankles, feet, or legs
      </p>
      <p>
      These are not all the possible side effects of SARCLISA. For more information, ask your healthcare
provider or pharmacist.
      </p>
      <p><strong>You are encouraged to report negative side effects of prescription drugs to the FDA. Visit <a href="https://www.fda.gov/medwatch" target="_blank">www.fda.gov/medwatch</a>, or call 1-800-FDA-1088.
        </strong></p>

    </div>
  );
};

export default Isi;
